import "./App.css";
import { Routes, Route } from "react-router-dom";
import OverviewInstantUnStaking from "./components/OverviewInstantUnStaking";
import Layout from "./components/Layout";
import { useStore } from "./store"; // importing module using path alias
import { useEffect } from "react";

function App() {
  const { commonStore } = useStore();
  useEffect(() => {
    if (commonStore.wallets === 0 && commonStore.loadingInfo === false) {
      commonStore.pullBasic().finally(() => commonStore.setBasicLoaded());
    }
  }, []);

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<OverviewInstantUnStaking />} />
        <Route path="instantunstaking" element={<OverviewInstantUnStaking />} />
        <Route path="home" element={<OverviewInstantUnStaking />} />
      </Route>
    </Routes>
  );
}

export default App;
