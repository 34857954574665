import { BN, units } from "@zilliqa-js/util";
import { toBech32Address } from "@zilliqa-js/crypto";
import { observable, makeObservable, runInAction } from "mobx";
import {
  cookieJar,
  countTestWallets,
  reputation,
  ssn,
  config,
} from "../common";
import { ZilliqaService } from "../services/ZilliqaService";
import { BlazeService } from "../services/Blaze";
/**
 *
 * @param number of milliseconds
 * @returns
 */
function delay(t: number) {
  return new Promise((resolve) => setTimeout(resolve, t));
}

export type jar = {
  balance: BN;
  pending: BN;
  fee: BN;
};

export type d = {
  transactionsAll: any;
  state: any;
  subState: any;
};

export type unstakeDataType = {
  name: string;
  balance: number;
  pending: number;
};

export type walletDataType = {
  name: string;
  wallets: number;
};

export type tierDataType = {
  day: number;
  tier1: number;
  tier2: number;
  tier3: number;
  tier4: number;
  tier5: number;
  tier6: number;
  tier7: number;
};

export type feeDataType = {
  name: string;
  fee: number;
};

export type timeFrameType = "2 weeks" | "1 month" | "3 months" | "6 months";

export class CommonStore {
  wallets = 0;
  loadingInfo: boolean = false;
  infoLoaded: boolean = false;
  timeFrame: timeFrameType = "2 weeks";

  zilliqa: ZilliqaService = new ZilliqaService("mainnet");
  blaze: BlazeService = new BlazeService("mainnet");

  cookie: jar = {
    balance: new BN(0),
    pending: new BN(0),
    fee: new BN(0),
  };

  format = {
    thousandSeparator: ".",
    decimalSeparator: ",",
  };

  _cookieJar: d = {
    state: { _balance: 0 },
    subState: {},
    transactionsAll: [],
  };
  _reputation: d = {
    state: {},
    subState: {},
    transactionsAll: [],
  };
  _tiers: d = {
    state: {},
    subState: {},
    transactionsAll: [],
  };
  _config: d = {
    state: {},
    subState: {},
    transactionsAll: [],
  };
  _ssn: d = {
    state: {},
    subState: {},
    transactionsAll: [],
  };

  unstakeData: unstakeDataType[] = [];
  walletData: walletDataType[] = [];
  tierData: walletDataType[] = [];
  feeData: feeDataType[] = [];

  constructor() {
    makeObservable(this, {
      wallets: observable,
      loadingInfo: observable,
      infoLoaded: observable,
      timeFrame: observable,
      cookie: observable,
      unstakeData: observable,
      walletData: observable,
      feeData: observable,
      // errors: observable,
      // values: observable,
      // setUsername: action,
      // setEmail: action,
      // setPassword: action,
      // reset: action,
      // login: action,
      // register: action,
      // logout: action,
    });
  }

  async pullBasic(timeFrame: timeFrameType = "2 weeks") {
    runInAction(() => {
      this.timeFrame = timeFrame;
    });
    const days = timerameToDays(timeFrame);

    if (this.loadingInfo) {
      return delay(1);
    }
    runInAction(() => {
      this.loadingInfo = true;
    });
    // some data fetching blockchain

    const cs = this.zilliqa.getState(cookieJar).then((result) => {
      this._cookieJar.state = result;
    });

    const rs = this.zilliqa.getState(reputation).then((result) => {
      this._reputation.state = result;
    });

    const conS = this.zilliqa.getState(config).then((result) => {
      this._config.state = result;
    });

    const ssns = this.zilliqa
      .getSubState(ssn, "withdrawal_pending")
      .then((result) => {
        this._ssn.subState.withdrawal_pending =
          result.withdrawal_pending[cookieJar.toLowerCase()];
      });

    const timeStamp = (Date.now() - 60 * 60 * 24 * days * 1000) * 1000;

    const cookT = this.blaze
      .transactionsAdvanced(toBech32Address(cookieJar), [
        { value: timeStamp.toString(), field: "timestamp", action: "gte" },
      ])
      .then((result) => {
        this._cookieJar.transactionsAll = result;
      });

    const repT = this.blaze
      .transactionsAdvanced(toBech32Address(reputation), [
        { value: timeStamp.toString(), field: "timestamp", action: "gte" },
      ])
      .then((result) => {
        this._reputation.transactionsAll = result;
      });

    const repTier = this.blaze
      .transactionsAdvanced(toBech32Address(reputation), [
        { value: "SetTiers".toString(), field: "data", action: "regex" },
      ])
      .then((result) => {
        this._tiers.transactionsAll = result;
      });

    const configT = this.blaze
      .transactionsAdvanced(toBech32Address(config))
      .then((result) => {
        this._config.transactionsAll = result;
      });

    await Promise.all([cs, rs, ssns, cookT, repT, configT, conS, repTier]).then(
      () => {
        runInAction(() => {
          this.cookie.balance = new BN(this._cookieJar.state._balance);
          this.wallets =
            Object.keys(this._reputation.state.address_to_reputation).length -
            countTestWallets;

          const pending = Object.values<BN>(
            this._ssn.subState.withdrawal_pending
          ).reduce((sum: BN, current: any) => {
            return sum.add(new BN(current));
          }, new BN("0"));
          this.cookie.pending = pending;

          this.cookie.fee = new BN(
            this._config.state.config_uint128[
              "CookieJar.SellStakeToCookieJar.fee"
            ]
          );

          this.makeUnstakeData(days);
          this.makeWalletData(days);
          this.makeFeeData(days);

          this.loadingInfo = false;
        });
      }
    );
    return undefined;
  }

  makeUnstakeData(days: number = 14) {
    let date: Date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    let data: unstakeDataType[] = [
      {
        name: date.getUTCDate() + 1 + "-" + (date.getUTCMonth() + 1),
        balance: parseInt(units.fromQa(this.cookie.balance, units.Units.Zil)),
        pending: parseInt(units.fromQa(this.cookie.pending, units.Units.Zil)),
      },
    ];

    let balance = this.cookie.balance;
    let pending = this.cookie.pending;

    let txIndex = 0;
    for (let index = 0; index < days; index++) {
      // console.log(index, date);
      while (
        txIndex < this._cookieJar.transactionsAll.length &&
        this._cookieJar.transactionsAll[txIndex].timestamp / 1000 >
          date.getTime()
      ) {
        const trans = this._cookieJar.transactionsAll[txIndex];

        // example wih buffered also taken https://viewblock.io/zilliqa/tx/0xcc58bcbb1c017d3e401cf82a41c96a98dd7cbafc0d7c4678a337b1c1490ab53b

        if (trans.data.includes("SellStakeToCookieJar")) {
          // console.log("SellStakeToCookieJar", trans);

          // catch any pending stuff taken
          const sp = trans.receipt.event_logs.filter(
            (item: { _eventname: string }) =>
              item._eventname === "SwapPendingWithdrawalNewEntry"
          );
          sp.forEach((element: any) => {
            const deposit = element.params.find(
              (it: { vname: string }) => it.vname === "deposit"
            );
            if (deposit) {
              pending = pending.sub(new BN(deposit.value + ""));
            } else {
              console.warn("no deposit found", trans.txHash);
            }
          });

          // catch the transfer
          const tr = trans.receipt.event_logs.filter(
            (item: { _eventname: string }) =>
              item._eventname === "Deleg withdraw deposit"
          );
          tr.forEach((element: any) => {
            const t = element.params.filter(
              (item: { vname: string }) => item.vname === "withdraw_amount"
            );
            if (t) {
              pending = pending.sub(new BN(t[0].value + ""));
            }
          });

          // catch the payment to stake
          const AddFunds = trans.receipt.transitions.filter(
            (item: { msg: { _tag: string } }) => item.msg._tag === "AddFunds"
          );
          if (AddFunds && AddFunds.length > 1) {
            balance = balance.add(new BN(AddFunds[1].msg._amount + ""));
          }
        } else if (trans.data.includes("CompleteWithdrawal")) {
          // console.log("CompleteWithdrawal", trans);
          const tr = trans.receipt.event_logs.filter(
            (item: { _eventname: string }) =>
              item._eventname === "CompleteWithdrawal"
          );
          tr.forEach((element: any) => {
            const t = element.params.filter(
              (item: { vname: string }) => item.vname === "amount"
            );
            if (t) {
              balance = balance.sub(new BN(t[0].value + ""));
              pending = pending.add(new BN(t[0].value + ""));
            }
          });
        } else if (trans.data.includes("SendFunds")) {
          // console.log("handle SendFunds", trans);
          const AddFunds = trans.receipt.transitions.filter(
            (item: { msg: { _tag: string } }) => item.msg._tag === "AddFunds"
          );
          if (AddFunds) {
            if (AddFunds[0].addr === cookieJar.toLowerCase()) {
              // console.log("outgoing transfer");
              balance = balance.add(new BN(AddFunds[0].msg._amount + ""));
            } else {
              // console.log("incomming transfer");
              balance = balance.sub(new BN(AddFunds[0].msg._amount + ""));
            }
          }
        } else if (trans.data.includes("AddFunds")) {
          // console.log("handle AddFunds", trans);
          if (trans.toAddr !== cookieJar.substring(2).toLowerCase()) {
            // console.log("outgoing transfer");
            balance = balance.add(new BN(trans.amount + ""));
          } else {
            // console.log("incomming transfer");
            balance = balance.sub(new BN(trans.amount + ""));
          }
        } else if (
          trans.data.includes("WithdrawStakeAmt") ||
          trans.data.includes("ConfirmDelegatorSwap") ||
          trans.data.includes("RequestDelegatorSwap")
        ) {
          // do nothing for now
        } else {
          console.warn("handle transaction", trans);
        }

        txIndex++;
      }

      let item = {
        name: date.getUTCDate() + "-" + (date.getUTCMonth() + 1),
        balance: parseInt(units.fromQa(balance, units.Units.Zil)),
        pending: parseInt(units.fromQa(pending, units.Units.Zil)),
      };
      data.push(item);
      date.setDate(date.getDate() - 1);
    }

    this.unstakeData = data.reverse();
  }

  makeWalletData(days: number = 14) {
    let date: Date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    let data: walletDataType[] = [
      {
        name: date.getUTCDate() + 1 + "-" + (date.getUTCMonth() + 1),
        wallets: this.wallets,
      },
    ];

    let wallets = this.wallets;

    let txIndex = 0;
    for (let index = 0; index < days; index++) {
      // console.log(index, date);
      while (
        txIndex < this._reputation.transactionsAll.length &&
        this._reputation.transactionsAll[txIndex].timestamp / 1000 >
          date.getTime()
      ) {
        const trans = this._reputation.transactionsAll[txIndex];

        if (trans.data.includes("CreateReputation")) {
          // console.log("CreateReputation", trans);

          wallets--;
        } else if (trans.data.includes("GiveReputation")) {
          // do nothing only gives existing accounts rep
        } else if (trans.data.includes("SetTiers")) {
          //console.log("SetTiers", trans.data);
        } else {
          console.warn("handle rep transaction", trans.data);
        }

        txIndex++;
      }
      if (wallets <= 0) {
        wallets = 0;
      }

      let item = {
        name: date.getUTCDate() + "-" + (date.getUTCMonth() + 1),
        wallets: wallets,
      };
      data.push(item);
      date.setDate(date.getDate() - 1);
    }

    this.walletData = data.reverse();
  }

  makeFeeData(days: number = 14) {
    let date: Date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    let fee = this.cookie.fee.toNumber();

    let endTiers: tierDataType = {
      day: 0,
      tier1: this._reputation.state.tiers[0].arguments[1],
      tier2: this._reputation.state.tiers[1].arguments[1],
      tier3: this._reputation.state.tiers[2].arguments[1],
      tier4: this._reputation.state.tiers[3].arguments[1],
      tier5: this._reputation.state.tiers[4].arguments[1],
      tier6: this._reputation.state.tiers[5].arguments[1],
      tier7: this._reputation.state.tiers[6].arguments[1],
    };

    // doorlopen en fee changes pakken
    let feeList = [];
    let txIndex = 0;
    let tierList = [];
    let tierIndex = 0;
    let tiers = endTiers;

    for (let index = 0; index < 365; index++) {
      // console.log(index, date);

      // fee check
      while (
        txIndex < this._config.transactionsAll.length &&
        this._config.transactionsAll[txIndex].timestamp / 1000 > date.getTime()
      ) {
        const trans = this._config.transactionsAll[txIndex];

        if (trans.data.includes("UpdateUintConfig")) {
          // console.log("UpdateUintConfig", trans.data);
          const start =
            trans.data.indexOf("CookieJar.SellStakeToCookieJar.fee") + 37;
          const end = trans.data.indexOf("]}", start);

          fee = parseInt(trans.data.substr(start, end - start - 1)) / 100;
          feeList.push({ day: index, fee: fee });
        } else if (
          trans.data.includes("UpdateByStrConfig") ||
          trans.data.includes("UpdateByStr33Config")
        ) {
          // do nothing we don't do fee changes in these ones
        } else {
          console.warn("handle config transaction", trans.data);
        }

        txIndex++;
      }

      // tiers check
      while (
        tierIndex < this._tiers.transactionsAll.length &&
        this._tiers.transactionsAll[tierIndex].timestamp / 1000 > date.getTime()
      ) {
        const trans = this._tiers.transactionsAll[tierIndex];

        tiers.day = index;

        let start = trans.data.indexOf("arguments") + 11;
        let end = trans.data.indexOf("}", start);
        let str = trans.data.substr(start, end - start - 2);
        let start2 = str.indexOf(",");
        tiers.tier1 = parseInt(str.substr(start2 + 2)) / 100;

        let lstr = trans.data.substr(start + start2);
        start = lstr.indexOf("arguments") + 11;
        end = lstr.indexOf("}", start);
        str = lstr.substr(start, end - start - 2);
        start2 = str.indexOf(",");
        tiers.tier2 = parseInt(str.substr(start2 + 2)) / 100;

        lstr = lstr.substr(start + start2);
        start = lstr.indexOf("arguments") + 11;
        end = lstr.indexOf("}", start);
        str = lstr.substr(start, end - start - 2);
        start2 = str.indexOf(",");
        tiers.tier3 = parseInt(str.substr(start2 + 2)) / 100;

        lstr = lstr.substr(start + start2);
        start = lstr.indexOf("arguments") + 11;
        end = lstr.indexOf("}", start);
        str = lstr.substr(start, end - start - 2);
        start2 = str.indexOf(",");
        tiers.tier4 = parseInt(str.substr(start2 + 2)) / 100;

        lstr = lstr.substr(start + start2);
        start = lstr.indexOf("arguments") + 11;
        end = lstr.indexOf("}", start);
        str = lstr.substr(start, end - start - 2);
        start2 = str.indexOf(",");
        tiers.tier5 = parseInt(str.substr(start2 + 2)) / 100;

        lstr = lstr.substr(start + start2);
        start = lstr.indexOf("arguments") + 11;
        end = lstr.indexOf("}", start);
        str = lstr.substr(start, end - start - 2);
        start2 = str.indexOf(",");
        tiers.tier6 = parseInt(str.substr(start2 + 2)) / 100;

        lstr = lstr.substr(start + start2);
        start = lstr.indexOf("arguments") + 11;
        end = lstr.indexOf("}", start);
        str = lstr.substr(start, end - start - 2);
        start2 = str.indexOf(",");
        tiers.tier7 = parseInt(str.substr(start2 + 2)) / 100;

        tierIndex++;
      }
      tierList.push(tiers);
      date.setDate(date.getDate() - 1);
    }

    feeList = feeList.reverse();
    tierList = tierList.reverse();

    // doorlopen en fee changes van oud naar nieuw pakken
    let fIndex = 0;
    let tiIndex = 0;
    let dayFee = feeList[0].fee;
    let dayTier = tierList[0];

    let datum: Date = new Date();
    datum.setHours(0);
    datum.setMinutes(0);
    datum.setSeconds(0);
    datum.setMilliseconds(0);
    datum.setDate(datum.getDate() - days);
    let d = datum;
    let data2 = [];

    for (let index = days; index >= 0; index--) {
      while (feeList.length > fIndex && feeList[fIndex].day > index) {
        dayFee = feeList[fIndex].fee;
        fIndex++;
      }
      let item = {
        name: d.getUTCDate() + "-" + (d.getUTCMonth() + 1),
        fee: dayFee,
        tier1: 0,
        tier2: 0,
        tier3: 0,
        tier4: 0,
        tier5: 0,
        tier6: 0,
        tier7: 0,
      };

      while (tierList.length > tiIndex && tierList[tiIndex].day > index) {
        dayTier = tierList[fIndex];

        tiIndex++;
      }

      item = {
        name: d.getUTCDate() + "-" + (d.getUTCMonth() + 1),
        fee: dayFee,
        tier1: Math.round((dayFee / 100) * (100 - dayTier.tier1) * 100) / 100,
        tier2: Math.round((dayFee / 100) * (100 - dayTier.tier2) * 100) / 100,
        tier3: Math.round((dayFee / 100) * (100 - dayTier.tier3) * 100) / 100,
        tier4: Math.round((dayFee / 100) * (100 - dayTier.tier4) * 100) / 100,
        tier5: Math.round((dayFee / 100) * (100 - dayTier.tier5) * 100) / 100,
        tier6: Math.round((dayFee / 100) * (100 - dayTier.tier6) * 100) / 100,
        tier7: Math.round((dayFee / 100) * (100 - dayTier.tier7) * 100) / 100,
      };

      d.setDate(d.getDate() + 1);

      data2.push(item);
    }

    let today = data2[data2.length - 1];
    today.name = d.getUTCDate() + "-" + (d.getUTCMonth() + 1);
    data2.push(today);

    this.feeData = data2;
  }

  setBasicLoaded() {
    this.infoLoaded = true;
  }
}

function timerameToDays(timeFrame: timeFrameType) {
  switch (timeFrame) {
    case "2 weeks":
      return 14;
    case "1 month":
      return 30;
    case "3 months":
      return 90;
    case "6 months":
      return 180;

    default:
      return 14;
  }
}

export default new CommonStore();
