import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  // ResponsiveContainer,
  LineChart,
  Line,
  ResponsiveContainer,
} from "recharts";
import { Observer } from "mobx-react-lite";
import { useStore } from "../store";

export default function OverviewInstantUnStaking() {
  const { commonStore } = useStore();
  return (
    <Observer>
      {() => (
        <div className="content">
          <h2>Instant Unstaking</h2>
          {/* <ResponsiveContainer width="100%" height="100%"> */}
          <div style={{ width: "100%", height: 300 }}>
            <ResponsiveContainer>
              <AreaChart
                data={commonStore.unstakeData}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  style={{
                    fontSize: "80%",
                    fill: "#FFFFFFDE",
                  }}
                />
                <YAxis
                  tickFormatter={(tick) => {
                    return parseInt(tick / 1000000 + "") + " M";
                  }}
                  domain={["dataMin", "dataMax + 100000"]}
                  style={{
                    fontSize: "80%",
                    fill: "#FFFFFFDE",
                  }}
                />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="balance"
                  stackId="1"
                  stroke="#ed7b9f"
                  fill="#ed7b9f"
                />
                <Area
                  type="monotone"
                  dataKey="pending"
                  stackId="1"
                  stroke="#FD1662"
                  fill="#FD1662"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          <div style={{ width: "100%", height: 300 }}>
            <ResponsiveContainer>
              <LineChart
                data={commonStore.walletData}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <XAxis
                  dataKey="name"
                  style={{
                    fontSize: "80%",
                    fill: "#FFFFFFDE",
                  }}
                />
                <YAxis
                  dataKey="wallets"
                  domain={["dataMin", "dataMax + 100"]}
                  style={{
                    fontSize: "80%",
                    fill: "#FFFFFFDE",
                  }}
                />
                <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                <Line
                  type="linear"
                  dot={false}
                  dataKey="wallets"
                  stroke="#FD1662"
                  width={5}
                />
                <Tooltip />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div style={{ width: "100%", height: 300 }}>
            <ResponsiveContainer>
              <LineChart
                data={commonStore.feeData}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <XAxis
                  dataKey="name"
                  style={{
                    fontSize: "80%",
                    fill: "#FFFFFFDE",
                  }}
                />
                <YAxis
                  // dataKey="fee"
                  domain={["dataMin- 0.1", "dataMax + 0.1"]}
                  tickFormatter={(tick) => {
                    return tick + "%";
                  }}
                  style={{
                    fontSize: "80%",
                    fill: "#FFFFFFDE",
                  }}
                />
                <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                <Line
                  type="linear"
                  dot={false}
                  dataKey="fee"
                  stroke="#FD1662"
                  name="Fee"
                />
                <Line
                  type="linear"
                  dot={false}
                  dataKey="tier1"
                  stroke="#FD1662"
                  name="Tier 1"
                />
                <Line
                  type="linear"
                  dot={false}
                  dataKey="tier2"
                  stroke="#FD1662"
                  name="Tier 2"
                />
                <Line
                  type="linear"
                  dot={false}
                  dataKey="tier3"
                  stroke="#FD1662"
                  name="Tier 3"
                />
                <Line
                  type="linear"
                  dot={false}
                  dataKey="tier4"
                  stroke="#FD1662"
                  name="Tier 4"
                />
                <Line
                  type="linear"
                  dot={false}
                  dataKey="tier5"
                  stroke="#FD1662"
                  name="Tier 5"
                />
                <Line
                  type="linear"
                  dot={false}
                  dataKey="tier6"
                  stroke="#FD1662"
                  name="Tier 6"
                />
                <Line
                  type="linear"
                  dot={false}
                  dataKey="tier7"
                  stroke="#FD1662"
                  name="Tier 7"
                />
                <Tooltip />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </Observer>
  );
}
