import { Outlet } from "react-router-dom";
import { Observer } from "mobx-react-lite";
import { useStore } from "../store";
import { units } from "@zilliqa-js/util";
import { NumericFormat } from "react-number-format";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

export default function Layout() {
  const { commonStore } = useStore();

  return (
    <Observer>
      {() => (
        <div className="header">
          <Navbar className="navbar">
            <Container>
              <Navbar.Brand as={Link} to="/" className="nav-links">
                Spark
              </Navbar.Brand>
            </Container>
          </Navbar>

          {/* overall info */}
          <Container>
            <div className="content">
              <span>Current Wallets : {commonStore.wallets}</span>
              <br />
              <span>
                {"Instant Unstake Balance : "}
                <NumericFormat
                  displayType="text"
                  value={units
                    .fromQa(commonStore.cookie.balance, units.Units.Zil)
                    .replace(
                      commonStore.format.thousandSeparator,
                      commonStore.format.decimalSeparator
                    )}
                  thousandSeparator={commonStore.format.thousandSeparator}
                  decimalSeparator={commonStore.format.decimalSeparator}
                  decimalScale={2}
                />
                {" Zil"}
              </span>
              <br />
              <span>
                {"Pending Unstake Balance : "}
                <NumericFormat
                  displayType="text"
                  value={units
                    .fromQa(commonStore.cookie.pending, units.Units.Zil)
                    .replace(
                      commonStore.format.thousandSeparator,
                      commonStore.format.decimalSeparator
                    )}
                  thousandSeparator={commonStore.format.thousandSeparator}
                  decimalSeparator={commonStore.format.decimalSeparator}
                  decimalScale={2}
                />
                {" Zil"}
              </span>
              <br />
              <span>
                {"Total Pool : "}
                <NumericFormat
                  displayType="text"
                  value={units
                    .fromQa(
                      commonStore.cookie.pending.add(
                        commonStore.cookie.balance
                      ),
                      units.Units.Zil
                    )
                    .replace(
                      commonStore.format.thousandSeparator,
                      commonStore.format.decimalSeparator
                    )}
                  thousandSeparator={commonStore.format.thousandSeparator}
                  decimalSeparator={commonStore.format.decimalSeparator}
                  decimalScale={2}
                />
                {" Zil"}
              </span>
              <br />
              <span>
                {"Current cookieJar fee : "}
                {commonStore.cookie.fee.toNumber() / 100}
                {"%"}
              </span>
              <br />
              <span>
                <Button
                  className="button"
                  variant={
                    commonStore.timeFrame === "2 weeks"
                      ? "primary"
                      : "outline-primary"
                  }
                  onClick={
                    !commonStore.loadingInfo
                      ? () => commonStore.pullBasic("2 weeks")
                      : undefined
                  }
                  style={
                    commonStore.timeFrame === "2 weeks"
                      ? {
                          backgroundColor: "#FD1662",
                          color: "#FFFFFFDE",
                          borderColor: "#FFFFFFDE",
                        }
                      : { borderColor: "#FD1662", color: "#FFFFFFDE" }
                  }
                  disabled={commonStore.loadingInfo}
                >
                  2 weeks
                </Button>{" "}
                <Button
                  className="button"
                  variant={
                    commonStore.timeFrame === "1 month"
                      ? "primary"
                      : "outline-primary"
                  }
                  onClick={
                    !commonStore.loadingInfo
                      ? () => commonStore.pullBasic("1 month")
                      : undefined
                  }
                  style={
                    commonStore.timeFrame === "1 month"
                      ? {
                          backgroundColor: "#FD1662",
                          color: "#FFFFFFDE",
                          borderColor: "#FFFFFFDE",
                        }
                      : { borderColor: "#FD1662", color: "#FFFFFFDE" }
                  }
                  disabled={commonStore.loadingInfo}
                >
                  1 month
                </Button>{" "}
                <Button
                  className="button"
                  variant={
                    commonStore.timeFrame === "3 months"
                      ? "primary"
                      : "outline-primary"
                  }
                  onClick={
                    !commonStore.loadingInfo
                      ? () => commonStore.pullBasic("3 months")
                      : undefined
                  }
                  style={
                    commonStore.timeFrame === "3 months"
                      ? {
                          backgroundColor: "#FD1662",
                          color: "#FFFFFFDE",
                          borderColor: "#FFFFFFDE",
                        }
                      : { borderColor: "#FD1662", color: "#FFFFFFDE" }
                  }
                  disabled={commonStore.loadingInfo}
                >
                  3 months
                </Button>{" "}
                <Button
                  className="button"
                  variant={
                    commonStore.timeFrame === "6 months"
                      ? "primary"
                      : "outline-primary"
                  }
                  onClick={
                    !commonStore.loadingInfo
                      ? () => commonStore.pullBasic("6 months")
                      : undefined
                  }
                  style={
                    commonStore.timeFrame === "6 months"
                      ? {
                          backgroundColor: "#FD1662",
                          color: "#FFFFFFDE",
                          borderColor: "#FFFFFFDE",
                        }
                      : { borderColor: "#FD1662", color: "#FFFFFFDE" }
                  }
                  disabled={commonStore.loadingInfo}
                >
                  6 months
                </Button>{" "}
              </span>
            </div>
          </Container>
          <Container>
            <Outlet />
          </Container>
          <Container>
            <br />
          </Container>
        </div>
      )}
    </Observer>
  );
}
