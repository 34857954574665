import {
  gql,
  ApolloClient,
  InMemoryCache,
  DefaultOptions,
} from "@apollo/client";

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

/**
 * This class should be stateless and portable
 */
export class BlazeService {
  uri: string;
  apolloClient: any;

  constructor(network = "mainnet") {
    if (network === "mainnet") {
      this.uri = "https://blaze.ignitedao.io/graphql";
      // this.uri = "http://localhost:4000/graphql";
    } else {
      this.uri = "https://blazetestnet.ignitedao.io/graphql";
    }
    this.apolloClient = new ApolloClient({
      uri: this.uri,
      cache: new InMemoryCache(),
      defaultOptions: defaultOptions,
    });
  }

  async transactionsAdvanced(
    address: string,
    filter?: { field: string; value: string; action?: string }[]
  ) {
    // console.log("timestamp", timestamp);
    const result = await this.apolloClient.query({
      query: gql`
        query Query($address: String!, $filters: [filter]) {
          transactionsAdvanced(address: $address, filters: $filters) {
            txHash
            id
            amount
            data
            receipt {
              epoch_num
              success
              accepted
              event_logs {
                _eventname
                address
                params {
                  type
                  value
                  vname
                }
              }
              transitions {
                accepted
                addr
                msg {
                  _amount
                  _recipient
                  _tag
                  params {
                    type
                    value
                    vname
                  }
                }
              }
            }
            senderAddr
            toAddr
            timestamp
          }
        }
      `,
      variables: filter
        ? {
            address: address,
            filters: filter,
          }
        : {
            address: address,
            // filters: {
            //   field: "txHash",
            //   value: txhashes,
            // },
          },
    });

    return result.data.transactionsAdvanced;
  }
}
