import commonStore, { CommonStore } from "./CommonStore";

export type RootStore = {
  commonStore: CommonStore;
};

const rootStore: RootStore = {
  commonStore,
};

export default rootStore;
