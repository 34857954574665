import { Zilliqa } from "@zilliqa-js/zilliqa";

export class ZilliqaService {
  networkUri: string;
  chainId: number;
  msgVersion: number;
  zil: Zilliqa;

  // network: mainnet or testnet
  constructor(network = "mainnet") {
    if (network === "mainnet") {
      this.networkUri = "https://api.zilliqa.com";
      this.chainId = 1;
    } else {
      // defaults to testnet
      this.networkUri = "https://dev-api.zilliqa.com";
      this.chainId = 333;
    }
    this.msgVersion = 1;
    this.zil = new Zilliqa(this.networkUri);
  }

  public async getBalance(address: string): Promise<number> {
    try {
      const response = await fetch(this.networkUri, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: "1",
          jsonrpc: "2.0",
          method: "GetBalance",
          params: [address.replace("0x", "")],
        }),
      });
      const responseJson = await response.json();
      return responseJson.result.balance;
    } catch (error) {
      console.error(error);
    }
    return -1;
  }

  public async getAddressFromKey(key: string): Promise<string> {
    return this.zil.wallet.addByPrivateKey(key);
  }

  public async getState(address: string): Promise<any> {
    return this.zil.contracts.at(address).getState();
  }

  public async getSubState(
    address: string,
    property: string,
    indices?: string[]
  ): Promise<any> {
    return this.zil.contracts.at(address).getSubState(property, indices);
  }
}
